import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CategoryIcon from "@material-ui/icons/Category";

const TicketsQueueOrderBy = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOrderByDate = () => {
    const currentOrder = localStorage.getItem("orderby-data-queue");
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    localStorage.setItem("orderby-data-queue", newOrder);
    localStorage.removeItem("orderby-categoria-queue");
    handleMenuClose();
  };

  const handleOrderByCategory = () => {
    const currentOrder = localStorage.getItem("orderby-categoria-queue");
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    localStorage.setItem("orderby-categoria-queue", newOrder);
    localStorage.removeItem("orderby-data-queue");
    handleMenuClose();
  };


  return (
    <div style={{ marginTop: 4 }}>
      <IconButton onClick={handleOpenMenu}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{top:50}}
      >
       <MenuItem 
  onClick={handleOrderByDate} 
  style={{
    display: "flex", 
    alignItems: "center", 
    padding: "10px 16px",
    borderRadius: "2px",
    transition: "background-color 0.2s ease",
    fontSize: "1rem",
    color: "#333",
    fontWeight: 500
  }}
  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
>
  <CalendarTodayIcon 
    style={{ 
      marginRight: 8, 
      color: "#1976d2", // Cor do ícone
      fontSize: "1.2rem" 
    }} 
  />
  Classificar por Data
</MenuItem>

<MenuItem 
  onClick={handleOrderByCategory} 
  style={{
    display: "flex", 
    alignItems: "center", 
    padding: "10px 16px",
    borderRadius: "2px",
    transition: "background-color 0.2s ease",
    fontSize: "1rem",
    color: "#333",
    fontWeight: 500
  }}
  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
>
  <CategoryIcon 
    style={{ 
      marginRight: 8, 
      color: "#388e3c", // Cor do ícone
      fontSize: "1.2rem" 
    }} 
  />
  Classificar por Nome
</MenuItem>

      </Menu>
    </div>
  );
};

export default TicketsQueueOrderBy;
